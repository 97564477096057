import React from "react"
// import Elephant from "../../assets/icons/elephant-icon.svg"
// import Globe from "../../assets/icons/globe-icon.svg"
// import YogaElephant from "../../assets/icons/yoga-elephant-icon.svg"
import ZosterIcon from "../../assets/icons/zoster.svg"
import AutoScrollGallery from "./AutoScrollGallery"

export default function WorkDetails() {
  return (
    <div className="discover-section">
      <div className="page-bg pen"></div>
      <header className="hero df jcb fc" data-scroll-section="">
        <div className="header-container">
          <h1 className="title serif">Discover</h1>
          <div>
            <h1 className="title serif title-blue">the zoster in You</h1>
          </div>
        </div>
      </header>

      {/* <div className="zoster-icon-container"> */}
        {/* <ZosterIcon /> */}
      {/* </div> */}
      {/* <div className="section df jcb center">
        <div className="df jcb fc text-center">
          <Elephant />
          <span>Vetted Developers</span>
          <desc>
            we make sure that your app is flexible, maintainable and rock solid
            in the long term.
          </desc>
        </div>
        <div className="df jcb fc text-center">
          <Globe />
          <span>Remote Ready</span>
          <desc>
            we're happy to use any software you prefer, be it Slack, Teams,
            JIRA, Asana Trello etc.
          </desc>
        </div>
        <div className="df jcb fc text-center">
          <YogaElephant />
          <span>Zero Drama Only Karma</span>
          <desc>
            we are your own flexible full-time employees with short term
            commitments
          </desc>
        </div>
      </div> */}
    </div>
  )
}
