import React from 'react';
import Fade from 'react-reveal/Fade';
import Flat from "../../../assets/icons/benefits/flat.svg";
import Projects from "../../../assets/icons/benefits/projects.svg";
import Games from "../../../assets/icons/benefits/games.svg";
import Anywhere from "../../../assets/icons/benefits/anywhere.svg";
import Bonus from "../../../assets/icons/benefits/bonus.svg";
import Balance from "../../../assets/icons/benefits/balance.svg";
import { Grid } from '@mui/material';

const REASONS = [
	{
		icon: <Bonus />,
		title: 'Quarterly Bonus',
		details: 'We have a quarterly bonus cycle as we believe a continuous feedback cycle makes us better.'
	},
	{
		icon: <Balance />,
		title: 'Work Life Balance',
		details: 'We work to have a better life, not to be a disaster in the end. We encourage focused work over long working hours.'
	},
	{
		icon: <Flat />,
		title: 'Flat Hierarchy',
		details: 'Designations are for paperwork. We follow a minimum hierarchy model; a mentor is the most respected role here.'
	},
	{
		icon: <Anywhere />,
		title: 'Work From Anywhere',
		details: 'Work from Home, Mountain, Beach, Desert or our office. There are absolutely no restrictions.'
	},
	{
		icon: <Games />,
		title: 'Games, Movies & Retreats',
		details: 'Our internal club houses compete on a Quarterly and Yearly Basis and organise various events.'
	},
	{
		icon: <Projects />,
		title: 'International Projects',
		details: 'More than a job, be a part of something bigger, and have fun doing it.'
	}
]

const ReasonToWork = () => {
	const reasonItem = (reason) => {
		let { icon, title, details } = reason;

		return <div className="reason-item">
			<div className="icon">{icon}</div>
			<div className="title">{title}</div>
			<div className="details">{details}</div>
		</div>
	}

	return (
		<section className="reason-to-work">
			<Grid container >
				<Grid xs={12}>
					<header className="header">
						<div className="first">Reasons</div>
						<div className='second'>to work with us</div>
					</header>
				</Grid>
				<Grid container className="reasons-list" rowSpacing={{xs: 4, md: 8}} spacing={{ xs: 2, md: 3 }} >
					{REASONS.map((reason, index) => (
						<Grid item md={4} sm={6} xs={12} key={`feature-key${index}`}>
							<Fade up delay={100 * index + 1} >
								{reasonItem(reason)}
							</Fade>
						</Grid>
					))}
				</Grid>
			</Grid>
		</section>
	);
};

export default ReasonToWork;
